import React from 'react'
import PropTypes from 'prop-types'
import {Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { Helmet } from "react-helmet"

export const ImmunityTemplate = ({ title, request, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h3 className="title is-size-3x has-text-weight-bold is-bold-light">
                {title}<span style={{fontSize:"20px",position:"relative", top:"-10px"}}>&trade;</span>

              </h3>
              <p>{request}</p>


              <Helmet
                  attributes={{
                      class: 'ef-book-chapter'
                  }}
              />
              <div style={{textAlign: 'center', marginTop:"0px"}}>
              <Link className="btn" to="/forms/comment?page_id=0" style={{marginTop: "10px",paddingTop: "9px", width: "210px", boxShadow: "none", backgroundColor: "#ff0000", color: "white"}}>
                Comment
              </Link>
              </div>



              <PageContent className="is-size-5 ef-book-chapter" content={content} />



            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

ImmunityTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  request: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const Immunity = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout pageMetadata = {post.frontmatter.pageMetadata}>
      <ImmunityTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        request={post.frontmatter.request}
        content={post.html}
      />
    </Layout>
  )
}

Immunity.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Immunity

export const immunityQuery = graphql`
  query Immunity($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        pageMetadata {
          title
          description
        }
        title
        request
      }
    }
  }
`
